









































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import PopoverInput from '@/components/popover-input.vue'
import {
  apiGoodsDel,
  apiGoodsRename,
  apiGoodsSort,
  apiGoodsStatus,
} from '@/api/goods'

@Component({
  components: {
    LsDialog,
    LsPagination,
    PopoverInput,
  },
})
export default class GoodsPane extends Vue {
  $refs!: { paneTable: any }
  @Prop() value: any
  @Prop() pager!: any
  status = true
  selectIds: any[] = []

  get disabledBtn() {
    return !this.selectIds.length
  }

  // 批量上架/下架商品
  handleBatchStatus({ status, ids }: any) {
    apiGoodsStatus({
      ids: ids ? ids : this.selectIds,
      status,
    }).then(() => {
      this.$emit('refresh')
    })
  }

  // 批量删除商品
  handleBatchDelete(ids: any) {
    apiGoodsDel({
      ids: Array.isArray(ids) ? ids : this.selectIds,
    }).then(() => {
      this.$emit('refresh')
    })
  }

  handleSelect(val: any[]) {
    this.selectIds = val.map((item) => item.id)
  }
  // 全选商品
  handleselectAll() {
    this.$refs.paneTable.toggleAllSelection()
  }

  handleSort(sort: string, id: number) {
    apiGoodsSort({
      id,
      sort,
    }).then(() => {
      this.$emit('refresh')
    })
  }
  handleRename(val: string, id: number) {
    apiGoodsRename({
      id,
      name: val,
    }).then(() => {
      this.$emit('refresh')
    })
  }
}
