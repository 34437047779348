



































































































































































import {
  Component,
  Vue
} from 'vue-property-decorator'
import GoodsPane
  from '@/components/goods/goods-pane.vue'
import ExportData
  from '@/components/export-data/index.vue'
import {
  RequestPaging
} from '@/utils/util'
import {
  apiGoodsLists,
  apiGoodsOtherList
} from '@/api/goods'
import {
  GoodsType
} from '@/utils/type'

@Component({
  components: {
    GoodsPane,
    ExportData,
  },
})
export default class Goods extends Vue {
  queryObj = {
    // 关键词搜索
    keyword: '',
    // 分类搜素
    category_id: '',
    // 供应商搜索
    supplier_id: '',
    // 适用于设备尺寸图片
    goods_device_size: ''
  }
  // 尺寸列表
  deviceSizeList: any[] = []
  supplierList: any[] = []
  categoryList: any[] = []
  tabCount = {
    all_count: 0,
    sales_count: 0,
    sellout_count: 0,
    storage_count: 0,
    warning_count: 0,
  }

  pager = new RequestPaging()
  activeName: any = 'all'
  apiGoodsLists = apiGoodsLists

  getList(): void {
    this.pager
        .request({
          callback: apiGoodsLists,
          params: {
            // 传参
            type: GoodsType[this.activeName],
            ...this.queryObj,
          },
        })
        .then((res: any) => {
          this.tabCount = res?.extend
        })
  }

  // 处理重置
  handleReset() {
    this.queryObj = {
      keyword: '',
      category_id: '',
      supplier_id: '',
      goods_device_size: ''
    }
    this.getList()
  }

  // 获取商品其他列表，如商品分类列表、供应商列表
  getGoodsOtherList() {
    apiGoodsOtherList({
      type: 'all',
    }).then((res: any) => {
      this.categoryList = res?.category_list
      this.supplierList = res?.supplier_list
      this.deviceSizeList = res?.device_size_list
    })
  }

// 获取尺寸列表

  created() {
    this.getGoodsOtherList()
    this.getList()
  }
}
